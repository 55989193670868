<template>
  <div>
    <div>
      <div
        :class="`cw-product-card--${productName}`"
        class="cw-product-card"
      >
        <div class="cw-product-card__header px-4 py-6">
          <div>
            <div class="cw-product-card__icon text-center">
              <v-icon size="42">
                {{ productIcon }}
              </v-icon>
            </div>
            <p class="cw-product-card__title text-center">
              {{ humanReadableProductType }}
            </p>
          </div>

          <p class="cw-product-card__subtitle text-subtitle-1 text-center">
            {{ subtitle }}
          </p>

          <v-row
            class="cw-product-card__value-propositions mb-6"
            justify="center"
          >
            <div>
              <v-row
                v-for="(valueProp, i) in valuePropositions"
                :key="i"
                align="start"
                class="cw-product-card__value-proposition"
              >
                <v-col class="px-0 py-2" cols="auto">
                  <v-icon>{{ valueProp.icon }}</v-icon>
                </v-col>
                <v-col
                  class="pl-3 pr-0 py-2 text-body-2"
                >
                  {{ valueProp.text }}
                </v-col>
              </v-row>
            </div>
          </v-row>
        </div>

        <div class="cw-product-card__body pa-2">
          <div class="slant"/>
          <p
            class="
              mb-sm-1
              mt-sm-4
              mb-1
              mt-0
              text-center
              text-uppercase
            "
          >
            <translate>
              Select the loan amount
            </translate>
          </p>

          <v-row justify="center">
            <v-col
              :id="`cw-product-card__amount-wrapper--${productName}`"
              class="cw-product-card__amount"
            >
              <v-select
                v-if="product.amount"
                :id="`cw-product-card__amount--${productName}`"
                v-model="selectedAmount"
                :items="product.prices"
                append-outer-icon="add"
                menu-props="auto"
                prepend-icon="remove"
                hide-details
                solo
                @change="instalmentIndex = 0"
                @click:append-outer="increasePrice(product)"
                @click:prepend="decreasePrice(product)"
              >
                <template #item="data">
                  <span
                    :class="`cw-product-card__amount__option--${productName}--${data.item.amount}`"
                  >
                    {{ data.item.amount | currency({ locale }) }}
                  </span>
                </template>
                <template #selection="data">
                  <span
                    :class="`cw-product-card__amount__selection--${productName}`"
                  >
                    {{ data.item.amount | currency({ digits: 0, locale }) }}
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <p class="mt-6 text-uppercase">
            <translate>
              Please select a monthly installment
            </translate>
          </p>

          <v-row
            v-if="productInstalments"
            class="cw-product-card__instalment_amount"
            justify="center"
          >
            <v-btn
              v-for="(instalment, index) in productInstalments"
              :id="`cw-product-card__instalment_amount--${instalment.amount}`"
              :key="instalment.amount"
              :style="index === instalmentIndex ?
                `border-color: ${primaryColor};
                  background-color: ${primaryColor} !important;
                  color: #fff;` :
                `color: ${primaryColor};`"
              class="cw-product-card__instalment_button mb-1"
              outlined
              @click="
                setInstalmentIndex(index);
                $eventLogger.clickEvent($event);
              "
            >
              {{ instalment.amount | currency({ digits: 0, locale }) }}
            </v-btn>
          </v-row>

          <p class="mt-4 mb-0 text-uppercase">
            <translate
              :translate-params="{
                appliedLoanPeriod: loanPeriod,
              }"
            >
              The loan period: %{ appliedLoanPeriod }
            </translate>
          </p>

          <v-card-actions class="pa-6">
            <v-btn
              :id="`cw-product-card__submit--${productName}`"
              :color="secondaryColor"
              class="offerSelectionButton"
              block
              dark
              @click="
                startApplication();
                $eventLogger.clickEvent($event);
              "
            >
              <template>
                <translate>
                  Apply for a loan
                </translate>
              </template>
            </v-btn>
          </v-card-actions>
        </div>
      </div>

      <p class="px-4 pt-6 text-caption grey--text product_cards__disclaimer">
        {{ product.disclaimer }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'CwProductCard',

  props: {
    product: {
      type: Object,
      required: true,
    },

    programs: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    originalAmount: null,
    instalmentIndex: 0,
    selectedAmount: {},
  }),

  computed: {
    ...mapGetters({
      application: 'application/getInitialData',
      locale: 'application/locale',
    }),

    brand() {
      if (!this.product || !this.product.product) return '';

      return this.product.product.toLowerCase().split('.')[0];
    },

    program() {
      return this.programs[this.brand];
    },

    productKey() {
      return this.product.productKey;
    },

    productName() {
      if (!this.productType) return null;
      return this.program[this.productType].name;
    },

    productIcon() {
      if (!this.productType) return null;
      const icons = {
        continuousLoan: 'credit_card',
        instalmentLoan: 'toll',
      };

      return icons[this.productType];
    },

    humanReadableProductType() {
      if (!this.productType) return null;
      return this.program[this.productType].type;
    },

    productType() {
      return this.product.type;
    },

    productInstalments() {
      if (!this.selectedAmount) return [];

      return this.selectedAmount.instalments;
    },

    primaryColor() {
      if (!this.program || !this.program.colors) return '';
      return this.program.colors.primary;
    },

    secondaryColor() {
      if (!this.program || !this.program.colors) return '';
      return this.program.colors.secondary;
    },

    loanPeriod() {
      if (!this.productInstalments || !this.productInstalments.length) return '';

      const n = this.productInstalments[this.instalmentIndex].count;
      const periods = {
        instalmentLoan: this.$gettextInterpolate(this.$ngettext(
          '%{ n } mo',
          '%{ n } mos',
          n,
        ), { n }),
        continuousLoan: this.$gettext('Flexible'),
      };

      return periods[this.productType];
    },

    subtitle() {
      const titles = {
        continuousLoan: this.$gettext('Withdraw money as needed'),
        instalmentLoan: this.$gettext('When you need the amount immediately into your account'),
      };

      return titles[this.productType];
    },

    valuePropositions() {
      const valueProps = {
        continuousLoan: [
          {
            text: this.$gettext('Make additional withdrawals 24/7'),
            icon: 'check_circle',
          },
          {
            text: this.$gettext('Flexible payback time'),
            icon: 'check_circle',
          },
          {
            text: this.$gettext('14 day cancellation policy'),
            icon: 'check_circle',
          },
        ],
        instalmentLoan: [
          {
            text: this.$gettext('Loan amount all at once in to Your account'),
            icon: 'check_circle',
          },
          {
            text: this.$gettext('Fixed Payment schedule'),
            icon: 'check_circle',
          },
          {
            text: this.$gettext('14 day cancellation policy'),
            icon: 'check_circle',
          },
        ],
      };

      return valueProps[this.productType];
    },

    withdrawalAmountChanged() {
      return parseInt(this.originalAmount, 10) !== parseInt(this.loanAmount, 10);
    },

    drawButtonOutline() {
      const outlines = {
        continuousLoan: true,
        instalmentLoan: false,
      };

      return outlines[this.productType];
    },

    loanAmount() {
      if (!this.product.amount) return null;

      return this.product.amount.amount;
    },
  },

  mounted() {
    this.originalAmount = this.loanAmount;
    this.selectedAmount = cloneDeep(this.product.amount);
  },

  methods: {
    ...mapActions({
      initializeApplication: 'application/initializeApplication',
    }),

    setInstalmentIndex(index) {
      this.instalmentIndex = index;
    },

    increasePrice() {
      const { product } = this;
      if (
        product.prices
          .findIndex(
            price => price.amount === this.selectedAmount.amount,
          ) < product.prices.length - 1
      ) {
        const amount = product
          .prices[product.prices
            .findIndex(price => price.amount === this.selectedAmount.amount) + 1];
        this.selectedAmount = amount;
        this.instalmentIndex = 0;
      }
    },

    decreasePrice() {
      const { product } = this;
      if (
        product.prices
          .findIndex(price => price.amount === this.selectedAmount.amount) > 0
      ) {
        const amount = product
          .prices[product.prices
            .findIndex(price => price.amount === this.selectedAmount.amount) - 1];
        this.selectedAmount = amount;
        product.instalmentAmount = 0;
      }
    },

    async startApplication() {
      let loanParams = {
        productKey: this.productKey,
        brand: this.product.product,
        type: this.productType,
      };

      if (this.productType === 'continuousLoan') {
        loanParams = {
          ...loanParams,
          withdrawalAmount: this.loanAmount,
          withdrawalAmountChanged: this.withdrawalAmountChanged,
        };
      } else {
        loanParams = {
          ...loanParams,
          appliedLoanLimit: this.loanAmount,
          appliedInstalmentAmount: this.productInstalments[this.instalmentIndex].amount,
        };
      }

      this.$dataLayer.PushToECommerce(
        {
          params: {
            action: 'add',
            formData: { loanParams },
          },
        },
      );

      await this.initializeApplication(loanParams);

      this.submit({
        name: 'changePage',
        params: {
          hashId: this.application.hashId,
        },
      });
    },

    submit(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
.cw-product-card {
  &__amount {
    .v-input__append-outer,
    .v-input__prepend-outer {
      .v-input__icon {
        i {
          color: var(--header-bgcolor);
        }
      }
    }
  }
}
</style>
